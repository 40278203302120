import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';

import './anti-money-laundering.scss';

const AntiMoneyLaundering = injectIntl(({ intl }) => {
  const createList = ({ prefix, size, siblings, isNumerical, values }) => {
    const elements = [];
    for (let i = 0; i < size; i++) {
      elements.push(
        <li key={`${prefix}${i}`}>
          <FormattedMessage id={`${prefix}${i}`} values={values?.[i] ?? {}} />
          {siblings && siblings[i]}
        </li>,
      );
    }
    return isNumerical ? <ol>{elements}</ol> : <ul>{elements}</ul>;
  };

  const italicContent = (
    <span className={intl.locale === 'fr' ? 'italic' : undefined}>
      <FormattedMessage id="pages.anti-money-laundering.services-card" />
    </span>
  );

  return (
    <Layout pageName="anti-money-laundering-page">
      <div className="page-content-wrapper anti-money-laundering-page">
        <section className="page-content">
          <h1>
            <FormattedMessage id="pages.anti-money-laundering.h1" />
          </h1>
          <h2>
            <FormattedMessage id="pages.anti-money-laundering.h2" />
          </h2>
          <div className="aml-content">
            <div className="aml-questions">
              <h3>
                <FormattedMessage id="pages.anti-money-laundering.h3-0" />
              </h3>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p0" />
              </p>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p1" />
              </p>
              <h3>
                <FormattedMessage id="pages.anti-money-laundering.h3-1" />
              </h3>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p2" />
              </p>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p3" />
              </p>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p4" />
              </p>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p5" />
              </p>
              <h3>
                <FormattedMessage id="pages.anti-money-laundering.h3-2" />
              </h3>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p6" />
              </p>
              {createList({
                prefix: 'pages.anti-money-laundering.p6-li',
                size: 8,
                values: { 7: { servicesCard: italicContent } },
                isNumerical: false,
              })}
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p7" />
              </p>
              {createList({
                prefix: 'pages.anti-money-laundering.p7-li',
                size: 19,
                isNumerical: false,
              })}
              <p>
                <strong>
                  <FormattedMessage id="pages.anti-money-laundering.p8" />
                </strong>
                <FormattedMessage id="pages.anti-money-laundering.p9" />
              </p>
              <p>
                <FormattedMessage id="pages.anti-money-laundering.p10" />
              </p>
            </div>
            <div className="aml-callouts">
              <div>
                <div className="aml-callouts-header">
                  <h4>
                    <FormattedMessage id="pages.anti-money-laundering.canada-post.h4" />
                  </h4>
                </div>
                <p>
                  <FormattedMessage id="pages.anti-money-laundering.canada-post.p0" />
                  <strong>
                    <FormattedMessage id="pages.anti-money-laundering.canada-post.p1" />
                  </strong>
                  <FormattedMessage id="pages.anti-money-laundering.canada-post.p2" />
                </p>
              </div>
              <div>
                <div className="aml-callouts-header">
                  <h4>
                    <FormattedMessage id="pages.anti-money-laundering.timelines.h4" />
                  </h4>
                </div>
                {createList({
                  prefix: 'pages.anti-money-laundering.timelines.ol-li',
                  size: 3,
                  isNumerical: true,
                })}
              </div>
              <div>
                <div className="aml-callouts-header">
                  <h4>
                    <FormattedMessage id="pages.anti-money-laundering.more-info.h4" />
                  </h4>
                </div>
                <p>
                  <FormattedMessage id="pages.anti-money-laundering.more-info.p0" />
                  <strong>
                    <FormattedMessage id="pages.anti-money-laundering.more-info.p1" />
                  </strong>
                  <FormattedMessage id="pages.anti-money-laundering.more-info.p2" />
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
});

export default AntiMoneyLaundering;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.anti-money-laundering.page-title"
    descriptionKey="pages.anti-money-laundering.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
